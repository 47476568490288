@font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-Medium.eot');
    src:
            url('/fonts/ApexRounded-Medium.eot?#iefix') format('embedded-opentype'),
            url('/fonts/ApexRounded-Medium.woff2') format('woff2'),
            url('/fonts/ApexRounded-Medium.woff') format('woff'),
            url('/fonts/ApexRounded-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-Book.eot');
    src:
            url('/fonts/ApexRounded-Book.eot?#iefix') format('embedded-opentype'),
            url('/fonts/ApexRounded-Book.woff2') format('woff2'),
            url('/fonts/ApexRounded-Book.woff') format('woff'),
            url('/fonts/ApexRounded-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-BookItalic.eot');
    src:
            url('/fonts/ApexRounded-BookItalic.eot?#iefix') format('embedded-opentype'),
            url('/fonts/ApexRounded-BookItalic.woff2') format('woff2'),
            url('/fonts/ApexRounded-BookItalic.woff') format('woff'),
            url('/fonts/ApexRounded-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apex Rounded';
    src: url('/fonts/ApexRounded-Bold.eot');
    src:
            url('/fonts/ApexRounded-Bold.eot?#iefix') format('embedded-opentype'),
            url('/fonts/ApexRounded-Bold.woff2') format('woff2'),
            url('/fonts/ApexRounded-Bold.woff') format('woff'),
            url('/fonts/ApexRounded-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
